<template>
  <div>
    <b-sidebar fullheight overlay right v-model="showFilters">
      <div class="p-4">

        <h1 class="title is-size-4">Filter</h1>
        <b-field>
          <b-checkbox v-model="showOnlyAccredited">Accredited Firms</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="showOnlyUnderbar">Accepting Underbars</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="showOnlyPlatinum">Platinum Firms</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="showOnlyDiamond">Diamond Firms</b-checkbox>
        </b-field>
        <!--
        <b-field>
          <b-checkbox v-model="showOnlyOnline">Online Internships</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="showOnlyOnsite">Onsite Internships</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="showOnlyHybrid">Hybrid Internships</b-checkbox>
        </b-field>
        -->

        <hr class="m-2">
        <h1 class="title is-size-4">Sort</h1>
        <b-radio v-model="sortBy" native-value="az">A-Z</b-radio><br>
        <b-radio v-model="sortBy" native-value="za">Z-A</b-radio><br>
        <b-radio v-model="sortBy" native-value="accredited">Show accredited firms first</b-radio><br>
        <b-radio v-model="sortBy" native-value="platinum">Show Platinum firms first</b-radio><br>
        <b-radio v-model="sortBy" native-value="diamond">Show Diamond firms first</b-radio>

        <hr class="m-2">
        <h1 class="title is-size-4">View</h1>
        <b-radio v-model="view" native-value="gallery">Gallery</b-radio><br>
        <b-radio v-model="view" native-value="list">List</b-radio><br>

        <hr class="m-2">
        <b-button type="is-primary" expanded v-on:click="showFilters = false">Done</b-button>
      </div>
    </b-sidebar>

    <div class="main">
      <h1 class="title is-1 has-text-centered headline">Explore Private Firms</h1>

      <div class="switch-container">
        <div class="buttons columns is-centered is-mobile">
          <b-button type="is-primary is-light" outlined v-on:click="showFilters = true">Filter</b-button>
          <b-button type="is-primary is-light" outlined v-on:click="showFilters = true">Sort</b-button>
          <b-button type="is-primary is-light" outlined v-on:click="showFilters = true">View</b-button>
        </div>
        <div class="columns is-centered is-mobile">
          <div class="tags is-centered">
            <span class="tag is-primary" v-if="showOnlyAccredited">Accredited</span>
            <span class="tag is-primary" v-if="showOnlyUnderbar">Underbar</span>
            <span class="tag is-primary" v-if="showOnlyPlatinum">Platinum</span>
            <span class="tag is-primary" v-if="showOnlyDiamond">Diamond</span>
            <span class="tag is-primary" v-if="showOnlyOnline">Online</span>
            <span class="tag is-primary" v-if="showOnlyOnsite">Onsite</span>
            <span class="tag is-primary" v-if="showOnlyHybrid">Hybrid</span>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-6-desktop is-full-mobile">
            <div class="field has-addons">
              <div class="control is-expanded">
                <input class="input" type="text" placeholder="Search for a firm" v-model="searchInput" :disabled="searchFilter">
              </div>
              <div class="control">
                <button class="button is-primary"
                        v-on:click="searchFilter = searchInput.toLowerCase()"
                        v-if="!searchFilter"
                >
                  Search
                </button>
                <button class="button"
                        v-on:click="searchFilter = null; searchInput = null"
                        v-else
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showOnlyDiamond === true || showOnlyPlatinum === showOnlyDiamond">
        <h1 class="title has-text-centered">Diamond Firms</h1>
        <div class="columns is-variable is-5 is-multiline is-centered firms">
          <div v-if="loading === true" class="column is-half">
            <Loader></Loader>
          </div>

          <div v-for="firm in firms"
               v-if="
                (firm.accredited === true || showOnlyAccredited === false) &&
                (firm.underbar === true || showOnlyUnderbar === false) &&
                (firm.online === true || showOnlyOnline === false) &&
                (firm.onsite === true || showOnlyOnsite === false) &&
                (firm.hybrid === true || showOnlyHybrid === false) &&
                (!searchFilter || firm.name.toLowerCase().includes(searchFilter)) &&
                firm.premium === true
             "
               :key="firm.id"
               class="column"
               v-bind:class="{
                'is-full': view === 'list',
                'is-5-desktop is-half-tablet': firm.premium && view !== 'list',
                'is-3-desktop is-3-tablet': !firm.premium && view !== 'list'
             }"
          >
            <FirmList :firm="firm" v-if="view === 'list'"></FirmList>
            <FirmThumb :firm="firm" v-else></FirmThumb>
          </div>
        </div>
      </div>

      <div v-if="showOnlyPlatinum === true || showOnlyPlatinum === showOnlyDiamond">
        <h1 class="title has-text-centered mt-6">Platinum Firms</h1>
        <div class="columns is-variable is-5 is-multiline is-centered firms">
          <div v-if="loading === true" class="column is-half">
            <Loader></Loader>
          </div>

          <div v-for="firm in firms"
               v-if="
                (firm.accredited === true || showOnlyAccredited === false) &&
                (firm.underbar === true || showOnlyUnderbar === false) &&
                (firm.online === true || showOnlyOnline === false) &&
                (firm.onsite === true || showOnlyOnsite === false) &&
                (firm.hybrid === true || showOnlyHybrid === false) &&
                (!searchFilter || firm.name.toLowerCase().includes(searchFilter)) &&
                firm.premium === false
             "
               :key="firm.id"
               class="column"
               v-bind:class="{
                'is-full': view === 'list',
                'is-5-desktop is-half-tablet': firm.premium && view !== 'list',
                'is-3-desktop is-3-tablet': !firm.premium && view !== 'list'
              }"
          >
            <FirmList :firm="firm" v-if="view === 'list'"></FirmList>
            <FirmThumb :firm="firm" v-else></FirmThumb>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
import Loader from "../components/Loader";
import FirmThumb from "../components/FirmThumb";
import FirmList from "../components/FirmList";

export default {
  name: "AllEmployers",
  title: "Firms | Hirati: Ateneo Law School Career Fair 2023",
  components: {FirmThumb, FirmList, Loader},
  data() { return {
    select: this.$route.params.select,
    headline: null,
    firms: [],
    showFilters: false,
    showOnlyAccredited: false,
    showOnlyUnderbar: false,
    showOnlyPlatinum: false,
    showOnlyDiamond: false,
    showOnlyOnline: false,
    showOnlyOnsite: false,
    showOnlyHybrid: false,
    sortBy: 'az',
    searchInput: null,
    searchFilter: null,
    view: 'gallery',
    loading: false
  }},
  methods: {
    async initializePage(select) {
      this.loading = true
      this.firms = []
      this.select = select
      switch (select) {
        case 'all': this.headline = "All Firms"; break;
        case 'firms': this.headline = "Explore Private Firms"; break;
        case 'ngo': this.headline = "Explore NGOs"; break;
        case 'gov': this.headline = "Explore Government Agencies"; break;
      }

      let query = supabase
          .from('employers')
          .select('id, name, accredited, logo, premium, underbar, online, onsite, hybrid')
          .order('name', {ascending: true})
          .eq('cat','firms')

      const {data, error} = await query
      this.firms = data
      if (error) { console.log(error) }
      this.loading = false
    }
  },
  mounted() {
    this.initializePage(this.$route.params.select)
  },
  watch: {
    $route(to, from) {
      this.initializePage(this.$route.params.select)
    },
    sortBy(value) {
      const vm = this
      const sortAz = (a,b) => a.name.localeCompare(b.name)
      if (vm.firms.length) {
        switch (value) {
          case 'az':
            vm.firms.sort((a, b) => sortAz(a,b)); break;
          case 'za':
            vm.firms.sort((a, b) => -1 * a.name.localeCompare(b.name)); break;
          case 'accredited':
            vm.firms.sort((a,b)=>sortAz(a,b)).sort((a, b) => b.accredited - a.accredited); break;
          case 'platinum':
            vm.firms.sort((a,b)=>sortAz(a,b)).sort((a, b) => b.premium - a.premium); break;
          case 'diamond':
            vm.firms.sort((a,b)=>sortAz(a,b)).sort((a, b) => a.premium - b.premium); break;
        }
      }
    }
  }
}
</script>

<style scoped>
.main {
  padding: 6.9%;
  padding-top: 4.2%;
}

.headline, .firms {
  margin-top: 12px;
}

.switch-container {
  margin: 12px;
  margin-bottom: 36px;
}
</style>