<template>
  <div class="box columns is-vcentered is-multiline" :class="{'is-mobile':!firm.premium}">
    <div class="column" :class="{'is-full-tablet is-one-third-mobile':!firm.premium, 'is-two-fifths-tablet':firm.premium}">
      <div class="columns is-vcentered logo-image-container" :class="{'logo-image-container-premium':firm.premium,'is-centered':firm.premium}">
        <img class="logo-image"
             :class="{'premium-logo-image':firm.premium,'reg-logo-image':!firm.premium}"
             :src="`https://cdn.statically.io/img/${firm.logo.replace(/(https:\/\/)|-/g, '')}`">
      </div>
    </div>
    <div class="column firm-info">
      <b-tag type="is-gold" v-if="firm.premium">Diamond Sponsor</b-tag>
      <b-tag type="is-green" v-if="firm.accredited">Accredited</b-tag>
      <b-tag v-if="firm.underbar">Underbars Accepted</b-tag>
      <p class="is-size-5 has-text-primary-dark firm-name">{{ firm.name }}</p>
      <b-button outlined type="is-primary" rounded size="is-small" tag="a" :href="`/view/${firm.id}`" target="_blank">View Details</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirmThumb",
  props: ['firm']
}
</script>

<style scoped>
.firm-name {
  margin-top: 4px;
  margin-bottom: 12px;
  font-family: "Paper Tiger", sans-serif;
}

.box {
  border: solid 1px #1b1d31;
  height: 100%;
  padding: 8px;
  padding-bottom: 24px;
  box-shadow: none !important;
}

.logo-image-container {
  padding: 14px;
  padding-top: 8px;
}

.firm-info {
  padding-top: 0;
  padding-bottom: 24px;
}

.logo-image {
  max-height: 158px;
}

@media only screen and (min-width: 769px) {
  .reg-logo-image {
    max-width: 80%;
    max-height: 69px;
  }

  .logo-image-container-premium {
    min-height: 182px;
  }
}

@media only screen and (max-width: 768px) {
  .box {
    margin-bottom: 0px;
    padding: 4px;
    padding-top: 12px;
  }

  .premium-logo-image {
    max-height: 122px;
    margin-bottom: -10px;
    max-width: 80%;
  }

  .firm-info {
    padding-bottom: 12px;
  }
}
</style>